import * as React from "react";

import { EGDSCard, EGDSCardContentSection, EGDSCardLink } from "@egds/react-core/cards";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { EGDSFigure, EGDSImage } from "@egds/react-core/images";
import { EGDSHeading, EGDSText } from "@egds/react-core/text";
import { EGDSRating } from "@egds/react-core/rating";
import { EGDSSpacing } from "@egds/react-core/spacing";

import PriceLockup from "components/flexComponents/Hotels/views/HotelsViews/components/shared/PriceLockup";
import DistanceOrRating from "components/flexComponents/Hotels/views/HotelsViews/components/shared/DistanceOrRating";
import DescriptionOrReview from "components/flexComponents/Hotels/views/HotelsViews/components/shared/DescriptionOrReview";
import AmenitiesOrRecommendation from "components/flexComponents/Hotels/views/HotelsViews/components/shared/AmenitiesOrRecommendation";
import ValueProposition from "components/flexComponents/Hotels/views/HotelsViews/components/shared/ValueProposition";
import { getHotelLink, getHotelLinkRel } from "components/flexComponents/Hotels/components/HotelCard";

import { getOptimizedImageUrl } from "src/components/flexComponents/Hotels/utils/hotelOptimizedImage";
import { ratingIcon } from "components/utility/RatingUtil";
import { TrackedLink } from "components/utility/analytics/TrackedLink";

import { useLocalization } from "@shared-ui/localization-context";
import { HotelPriceLockup } from "src/components/flexComponents/Hotels/utils/A11y/HotelPriceLockup";
import { fireAffinityProductCardSelectedClickstreamEvent } from "src/components/flexComponents/Hotels/utils/fireAffinityProductCardSelectedClickstreamEvent";
import { HotelImageCardProps } from "src/components/flexComponents/Hotels/typings";

const HotelCard = (props: HotelImageCardProps) => {
  const { context, model, hotel, index } = props;
  const { callToAction, destinationId } = model;
  const { hotelName, hotelStarRating, hotelFormattedFullAddress } = hotel;

  const hotelImageUrl = getOptimizedImageUrl(hotel, context);

  const { formatText } = useLocalization();

  return (
    <EGDSCard padded onClick={fireAffinityProductCardSelectedClickstreamEvent(hotel, index)}>
      <EGDSLayoutFlex space="three">
        <EGDSLayoutFlexItem minWidth={250} shrink={0}>
          <div>
            <EGDSFigure className="uitk-card-roundcorner-all all-image-fit-cover">
              <EGDSImage src={hotelImageUrl} alt={hotel.hotelName} placeholderImage lazyLoading="lazy" />
            </EGDSFigure>
          </div>
        </EGDSLayoutFlexItem>
        <EGDSLayoutFlexItem grow={1}>
          <div>
            <EGDSCardContentSection padded={false}>
              <EGDSLayoutFlex direction="column">
                <EGDSSpacing margin={{ blockend: "three" }}>
                  <EGDSLayoutFlex justifyContent="space-between">
                    <EGDSLayoutFlex direction="column">
                      <EGDSHeading tag="h3" size={5}>
                        {hotelName}
                      </EGDSHeading>
                      <EGDSRating
                        rating={hotelStarRating}
                        icon={ratingIcon(context.locale, context.site.id)}
                        className={context.site.brand}
                      />
                      <EGDSText size={300}>{hotelFormattedFullAddress}</EGDSText>
                    </EGDSLayoutFlex>
                    <EGDSLayoutFlex>
                      <PriceLockup
                        hotel={hotel}
                        hotelLink={getHotelLink(hotel, model.destinationId, context)}
                        priceA11y={new HotelPriceLockup(hotel, formatText)}
                        hasGetRatesText
                        textAlignment="right"
                      />
                    </EGDSLayoutFlex>
                  </EGDSLayoutFlex>
                </EGDSSpacing>
                <EGDSSpacing margin={{ blockend: "three" }}>
                  <ValueProposition hotel={hotel} />
                </EGDSSpacing>
                <EGDSSpacing margin={{ blockend: "three" }}>
                  <div>
                    <DistanceOrRating model={model} hotel={hotel} context={context} />
                  </div>
                </EGDSSpacing>
              </EGDSLayoutFlex>
            </EGDSCardContentSection>
            <EGDSCardContentSection padded={false} border="top">
              <EGDSSpacing margin={{ blockstart: "three" }}>
                <DescriptionOrReview model={model} hotel={hotel} />
              </EGDSSpacing>
              <EGDSSpacing margin={{ blockstart: "three" }}>
                <AmenitiesOrRecommendation model={model} hotel={hotel} />
              </EGDSSpacing>
            </EGDSCardContentSection>
          </div>
        </EGDSLayoutFlexItem>
      </EGDSLayoutFlex>
      <EGDSCardLink>
        <TrackedLink
          data-testid={`hotelcard-link-${hotelName}`}
          aria-label={hotelName}
          moduleName="hotels"
          rfrr="card"
          rel={getHotelLinkRel(callToAction)}
          href={getHotelLink(hotel, destinationId || context.searchContext.location?.id, context, callToAction)}
        />
      </EGDSCardLink>
    </EGDSCard>
  );
};

export default HotelCard;
