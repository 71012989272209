import * as React from "react";

import { EGDSCard, EGDSCardContentSection, EGDSCardLink } from "@egds/react-core/cards";
import { EGDSLayoutGrid, EGDSLayoutGridItem } from "@egds/react-core/layout-grid";
import { EGDSLayoutFlex } from "@egds/react-core/layout-flex";
import { EGDSFigure, EGDSFigureAspectRatioType, EGDSImage } from "@egds/react-core/images";
import { EGDSHeading, EGDSText } from "@egds/react-core/text";
import { EGDSRating } from "@egds/react-core/rating";
import { EGDSSpacing } from "@egds/react-core/spacing";

import PriceLockup from "components/flexComponents/Hotels/views/HotelsViews/components/shared/PriceLockup";
import DistanceOrRating from "components/flexComponents/Hotels/views/HotelsViews/components/shared/DistanceOrRating";
import DescriptionOrReview from "components/flexComponents/Hotels/views/HotelsViews/components/shared/DescriptionOrReview";
import AmenitiesOrRecommendation from "components/flexComponents/Hotels/views/HotelsViews/components/shared/AmenitiesOrRecommendation";
import ValueProposition from "components/flexComponents/Hotels/views/HotelsViews/components/shared/ValueProposition";
import { getHotelLink, getHotelLinkRel } from "components/flexComponents/Hotels/components/HotelCard";

import { getOptimizedImageUrl } from "src/components/flexComponents/Hotels/utils/hotelOptimizedImage";
import { ratingIcon } from "components/utility/RatingUtil";
import { TrackedLink } from "components/utility/analytics/TrackedLink";

import { HotelPriceLockup } from "src/components/flexComponents/Hotels/utils/A11y/HotelPriceLockup";
import { useLocalization } from "@shared-ui/localization-context";
import { fireAffinityProductCardSelectedClickstreamEvent } from "src/components/flexComponents/Hotels/utils/fireAffinityProductCardSelectedClickstreamEvent";
import { HotelImageCardProps } from "src/components/flexComponents/Hotels/typings";

const HotelCard = (props: HotelImageCardProps) => {
  const { context, model, hotel, index } = props;
  const { callToAction, destinationId } = model;
  const { hotelName, hotelStarRating, hotelFormattedFullAddress } = hotel;

  const hotelImageUrl = getOptimizedImageUrl(hotel, context);

  const { formatText } = useLocalization();

  return (
    <EGDSCard onClick={fireAffinityProductCardSelectedClickstreamEvent(hotel, index)}>
      <EGDSLayoutGrid columns={3}>
        <EGDSLayoutGridItem colSpan={1}>
          <EGDSFigure ratio={EGDSFigureAspectRatioType.R21_9}>
            <EGDSImage src={hotelImageUrl} alt={hotel.hotelName} placeholderImage lazyLoading="lazy" />
          </EGDSFigure>
        </EGDSLayoutGridItem>

        <EGDSLayoutGridItem colSpan={2}>
          <EGDSCardContentSection>
            <EGDSLayoutFlex direction="column">
              <EGDSLayoutFlex justifyContent="space-between">
                <EGDSLayoutFlex direction="column">
                  <EGDSHeading tag="h3" size={5}>
                    {hotelName}
                  </EGDSHeading>
                  <EGDSRating
                    rating={hotelStarRating}
                    icon={ratingIcon(context.locale, context.site.id)}
                    className={context.site.brand}
                  />
                  <EGDSText size={300}>{hotelFormattedFullAddress}</EGDSText>
                </EGDSLayoutFlex>
                <EGDSLayoutFlex>
                  <PriceLockup
                    hotel={hotel}
                    hotelLink={getHotelLink(hotel, model.destinationId, context)}
                    priceA11y={new HotelPriceLockup(hotel, formatText)}
                    hasGetRatesText
                  />
                </EGDSLayoutFlex>
              </EGDSLayoutFlex>
              <EGDSSpacing margin={{ blockstart: "three" }}>
                <div>
                  <DistanceOrRating model={model} hotel={hotel} context={context} />
                </div>
              </EGDSSpacing>
              <EGDSSpacing margin={{ blockstart: "three" }}>
                <DescriptionOrReview model={model} hotel={hotel} />
              </EGDSSpacing>
              <EGDSSpacing margin={{ blockstart: "three" }}>
                <AmenitiesOrRecommendation model={model} hotel={hotel} />
              </EGDSSpacing>
              <EGDSSpacing margin={{ blockstart: "three" }}>
                <ValueProposition hotel={hotel} />
              </EGDSSpacing>
            </EGDSLayoutFlex>
          </EGDSCardContentSection>
        </EGDSLayoutGridItem>
      </EGDSLayoutGrid>
      <EGDSCardLink>
        <TrackedLink
          data-testid={`hotelcard-link-${hotelName}`}
          aria-label={hotelName}
          moduleName="hotels"
          rfrr="card"
          rel={getHotelLinkRel(callToAction)}
          href={getHotelLink(hotel, destinationId || context.searchContext.location?.id, context, callToAction)}
        />
      </EGDSCardLink>
    </EGDSCard>
  );
};

export default HotelCard;
