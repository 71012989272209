import { Hotel } from "typings/microserviceModels/hotels-flex-module";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { EGDSCardLink, EGDSCard } from "@egds/react-core/cards";
import { EGDSLayoutGrid, EGDSLayoutGridItem } from "@egds/react-core/layout-grid";
import { EGDSSpacing } from "@egds/react-core/spacing";
import * as React from "react";
import { CardMediaOverallInfo } from "./CardMediaOverallInfo";
import { FeaturedReviewContent } from "./FeaturedReviewContent";
import { TrackedLink } from "src/components/utility/analytics/TrackedLink";
import { observer } from "mobx-react";
import { HotelSearchLinkBuilder } from "components/utility/HotelSearchLinkBuilder/HotelSearchLinkBuilder";
import { HotelCardProps } from "components/flexComponents/Hotels/typings";
import { validatePrice } from "../views/HotelsViews/components/shared/PriceLockup";
import { useHotelsContext } from "src/components/flexComponents/Hotels/components/HotelsContext";
import { useInteractiveFilters } from "src/components/flexComponents/PropertyFiltersInteractive/PropertyFiltersInteractiveContext";
import { useLocalization } from "@shared-ui/localization-context";
import { useSendClickstreamSelectedEvent } from "src/components/flexComponents/Hotels/hooks/useSendClickstreamSelectedEvent";

export const HotelCard: React.FC<HotelCardProps> = observer(
  ({
    hotel,
    locationId,
    index,
    callToAction,
    context,
    CardContent,
    moduleName = "hotels",
    isMobile,
    showGuestRating,
    displayBadges,
    withBorder = true,
    tag,
    isViewCarousel,
  }) => {
    const { hotelName } = hotel;
    const gridProps = tag ? { tag } : {};
    const { formatText } = useLocalization();
    const { model } = useHotelsContext();
    const isImmediateFilteringEnabled = useInteractiveFilters();
    const isValidPrice = validatePrice(hotel);
    const linkTarget = isImmediateFilteringEnabled ? "_blank" : undefined;

    const hotelLink = getHotelLink(hotel, locationId, context, callToAction);

    return (
      <>
        {isViewCarousel ? (
          <EGDSSpacing margin={{ blockstart: "three" }}>
            <EGDSCard border={withBorder} data-testid="hotelcard-item-carousel">
              <CardMediaOverallInfo hotel={hotel} context={context} index={index + 1} displayBadges={displayBadges} />
              <div onClick={useSendClickstreamSelectedEvent(hotel, index)}>
                {CardContent && <CardContent />}
                <FeaturedReviewContent
                  context={context}
                  hotel={hotel}
                  hotelLink={hotelLink}
                  showGuestRating={showGuestRating}
                  hasGetRatesText={!isValidPrice && model.showGetPriceIfNoPrice}
                />
                <EGDSCardLink>
                  <TrackedLink
                    data-testid={`hotelcard-link-${index + 1}`}
                    className="uitk-card-link"
                    moduleName={moduleName}
                    rfrr={`card-${index}`}
                    rel={getHotelLinkRel(callToAction)}
                    href={hotelLink}
                    target={linkTarget}
                  >
                    {linkTarget && <span className="is-visually-hidden">{formatText("opens.in.new.window")}</span>}
                    {hotelName}
                  </TrackedLink>
                </EGDSCardLink>
              </div>
            </EGDSCard>
          </EGDSSpacing>
        ) : (
          <EGDSSpacing margin={{ blockstart: "three" }}>
            <EGDSCard border={withBorder}>
              <EGDSLayoutGrid columns={12} data-testid="hotelcard-item" {...gridProps}>
                <EGDSLayoutGridItem colSpan={isMobile ? "all" : 4}>
                  <CardMediaOverallInfo
                    hotel={hotel}
                    context={context}
                    index={index + 1}
                    displayBadges={displayBadges}
                  />
                </EGDSLayoutGridItem>
                <EGDSLayoutGridItem colSpan={isMobile ? "all" : 8}>
                  <div onClick={useSendClickstreamSelectedEvent(hotel, index)}>
                    {CardContent && <CardContent />}
                    <FeaturedReviewContent
                      context={context}
                      hotel={hotel}
                      hotelLink={hotelLink}
                      showGuestRating={showGuestRating}
                      hasGetRatesText={!isValidPrice && model.showGetPriceIfNoPrice}
                    />
                    <EGDSCardLink>
                      <TrackedLink
                        data-testid={`hotelcard-link-${index + 1}`}
                        className="uitk-card-link"
                        moduleName={moduleName}
                        rfrr={`card-${index}`}
                        rel={getHotelLinkRel(callToAction)}
                        href={hotelLink}
                        target={linkTarget}
                      >
                        {linkTarget && <span className="is-visually-hidden">{formatText("opens.in.new.window")}</span>}
                        {hotelName}
                      </TrackedLink>
                    </EGDSCardLink>
                  </div>
                </EGDSLayoutGridItem>
              </EGDSLayoutGrid>
            </EGDSCard>
          </EGDSSpacing>
        )}
      </>
    );
  }
);

/**
 * In case of pinnedHSR or pinnedHSRDated, we need to generate a rel="nofollow" link
 * @param callToAction
 */
export const getHotelLinkRel = (callToAction?: string) => {
  return callToAction && callToAction.startsWith("pinnedHSR") ? "nofollow" : null;
};

/**
 * Utility method to either:
 * - use the new HotelSearchLinkBuilder if we are going to pinned HSR or
 * - otherwise use the link given by hotels-flex-module backend
 * @param hotel
 * @param context
 * @param callToAction
 */
export const getHotelLink = (
  hotel: Hotel,
  locationId: string,
  context: ExtendedContextStore,
  callToAction?: string
) => {
  const { isoCheckIn, isoCheckOut, hotelId, hotelInfositeUrl } = hotel;

  if (callToAction && callToAction.startsWith("pinnedHSR")) {
    return new HotelSearchLinkBuilder()
      .withRegionId(locationId)
      .withStartDate(isoCheckIn)
      .withEndDate(isoCheckOut)
      .withPinnedHotelId(hotelId)
      .build(context);
  }

  return hotelInfositeUrl;
};
