import { Hotel } from "typings/microserviceModels/hotels-flex-module";
import * as React from "react";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { HotelNameRatingAndAddress } from "./HotelNameRatingAndAddress";
import { NearestLocationContent } from "./NearestLocation/NearestLocationContent";
import { useLocalization } from "@shared-ui/localization-context";
import { Description } from "./Description";
import { PriceLockup, validatePrice } from "../views/HotelsViews/components/shared/PriceLockup";
import { HotelPriceLockupWithDates } from "../utils/A11y/HotelPriceLockupWithDates";
import { getHotelLink } from "./HotelCard";
import { useHotelsContext } from "./HotelsContext";
import { EGDSLayoutGrid, EGDSLayoutGridItem, MinMax } from "@egds/react-core/layout-grid";
import { EGDSText } from "@egds/react-core/text";

/**
 * CardContent which contains the overall ratings, pricing, value proposition
 * and guest amount (hard coded)
 */
export const CardContentReviewsPriceProposition = ({
  hotel,
  locale,
  locationName,
  context,
  isViewCarousel,
  showDistance,
  showAddress,
  shouldUseImperialDistanceUnits,
  addressDistanceView,
}: {
  hotel: Hotel;
  locale: string;
  locationName: string;
  context: ExtendedContextStore;
  isViewCarousel?: boolean;
  showDistance?: boolean;
  showAddress?: boolean;
  shouldUseImperialDistanceUnits?: boolean;
  addressDistanceView?: string;
}) => {
  const { formatText, formatDateString } = useLocalization();
  const { model } = useHotelsContext();
  const isValidPrice = Boolean(validatePrice(hotel));
  const hotelLink = getHotelLink(hotel, model.destinationId, context);
  const hasPriceLockup = isValidPrice && model.showGetPriceIfNoPrice;
  const priceLockup = hasPriceLockup && (
    <EGDSLayoutGridItem>
      <div>
        <PriceLockup
          hotel={hotel}
          hotelLink={hotelLink}
          priceA11y={new HotelPriceLockupWithDates(hotel, formatText, formatDateString)}
          hasGetRatesText={isValidPrice}
        />
      </div>
    </EGDSLayoutGridItem>
  );

  return (
    <>
      {isViewCarousel ? (
        <>
          <EGDSText overflow="truncate" size={500} weight="bold">
            {hotel.hotelName}
          </EGDSText>
          <EGDSLayoutGrid
            space="two"
            columns={hasPriceLockup ? [MinMax("0x", "3fr"), MinMax("min-content", "1fr")] : 1}
          >
            <EGDSLayoutGridItem>
              <div>
                <HotelNameRatingAndAddress
                  hotel={hotel}
                  locale={locale}
                  context={context}
                  isViewCarousel={isViewCarousel}
                  showAddress={showAddress}
                  showDistance={showDistance}
                  locationName={locationName}
                  shouldUseImperialDistanceUnits={shouldUseImperialDistanceUnits}
                  addressDistanceView={addressDistanceView}
                />
              </div>
            </EGDSLayoutGridItem>
            {priceLockup}
          </EGDSLayoutGrid>
          <Description hotel={hotel} truncate={model.truncateDescription} />
          <NearestLocationContent
            context={context}
            model={hotel.directDistance}
            shouldUseImperialDistanceUnits={shouldUseImperialDistanceUnits}
          />
        </>
      ) : (
        <>
          <EGDSLayoutGrid
            space="two"
            columns={hasPriceLockup ? [MinMax("0x", "3fr"), MinMax("min-content", "1fr")] : 1}
          >
            <EGDSLayoutGridItem>
              <div>
                <HotelNameRatingAndAddress
                  hotel={hotel}
                  locale={locale}
                  context={context}
                  isViewCarousel={isViewCarousel}
                  showAddress={showAddress}
                  showDistance={showDistance}
                  locationName={locationName}
                  shouldUseImperialDistanceUnits={shouldUseImperialDistanceUnits}
                  addressDistanceView={addressDistanceView}
                />
              </div>
            </EGDSLayoutGridItem>
            {priceLockup}
          </EGDSLayoutGrid>
          <Description hotel={hotel} truncate={model.truncateDescription} />
          <NearestLocationContent
            context={context}
            model={hotel.directDistance}
            shouldUseImperialDistanceUnits={shouldUseImperialDistanceUnits}
          />
        </>
      )}
    </>
  );
};
