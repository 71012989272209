// External Libraries
import * as React from "react";

// Types
import { Hotel } from "typings/microserviceModels/hotels-flex-module";

// UITK
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSText } from "@egds/react-core/text";

// Components
import { ReviewCountAndRating } from "components/flexComponents/Hotels/components/ReviewCountAndRating";
import { showReviewCountAndRating } from "components/flexComponents/Hotels/components/FeaturedReviewContent";
import { useHotelsContext } from "components/flexComponents/Hotels/components/HotelsContext";
import { HotelName, HotelRating } from "components/flexComponents/Hotels/components/HotelNameRatingAndAddress";
import DistanceOrRating from "components/flexComponents/Hotels/views/HotelsViews/components/shared/DistanceOrRating";

// Utils
import { getAddress } from "components/flexComponents/Hotels/utils/getAddress";
import { ratingIcon } from "components/utility/RatingUtil";
import PriceLockup from "../../views/HotelsViews/components/shared/PriceLockup";
import { HotelPriceLockupWithDates } from "../../utils/A11y/HotelPriceLockupWithDates";
import { getHotelLink } from "../HotelCard";
import { isVariantEnabled } from "src/components/utility/experiment";
import { ViewLarge, Viewport, ViewSmall } from "@shared-ui/viewport-context";
import { useLocalization } from "@shared-ui/localization-context";

export interface SummarizedContentViewProps {
  showPrice?: boolean;
  showAddress?: boolean;
  showNeighborhood?: boolean;
  showDistance?: boolean;
  showRating?: boolean;
}

interface SummarizedContentProps extends SummarizedContentViewProps {
  hotel: Hotel;
}

export const SummarizedContent: React.FC<SummarizedContentProps> = ({
  hotel,
  showPrice = true,
  showAddress = true,
  showNeighborhood = false,
  showDistance = false,
  showRating = false,
}) => {
  const {
    hotelName,
    reviewCount,
    hotelOverallReviewRating,
    featuredReviews,
    hotelStarRating,
    neighborhoodName,
    scoreDenominator,
  } = hotel;
  const { context, model } = useHotelsContext();
  const { formatText, formatDateString } = useLocalization();

  const language = context.locale?.split(/[_-]/);
  const hotelRatingIcon = ratingIcon(context.locale, context.site?.id);
  const brand = context.site.brand;

  const isVariant = isVariantEnabled(context, "Progressive_disclosure_of_content_DLPs");

  const reviewCountAndRating = showReviewCountAndRating(
    model.showGuestRating,
    reviewCount,
    hotelOverallReviewRating,
    featuredReviews
  ) && (
    <ReviewCountAndRating
      hotelOverallReviewRating={hotelOverallReviewRating}
      reviewCount={reviewCount}
      language={language}
      scoreDenominator={scoreDenominator}
      brand={brand}
    />
  );

  const priceSection = showPrice && (
    <EGDSLayoutFlexItem shrink={0}>
      <div>
        <PriceLockup
          hotel={hotel}
          hotelLink={getHotelLink(hotel, model.destinationId, context)}
          priceA11y={new HotelPriceLockupWithDates(hotel, formatText, formatDateString)}
          hasGetRatesText
        />
      </div>
    </EGDSLayoutFlexItem>
  );

  const titleAndDescription = (
    <EGDSSpacing margin={{ inlineend: "one" }}>
      <div>
        <EGDSSpacing margin={{ blockend: "one" }}>
          <div>
            <HotelName tag="h3" overflow="truncate-2-lines" hotelName={hotelName} />
            {showRating && (
              <EGDSSpacing margin={{ blockend: "one" }}>
                <HotelRating hotelStarRating={hotelStarRating} hotelRatingIcon={hotelRatingIcon} />
              </EGDSSpacing>
            )}
          </div>
        </EGDSSpacing>
        {showAddress && (
          <EGDSSpacing padding={{ blockend: "one" }}>
            <EGDSText size={300}>{getAddress(hotel)}</EGDSText>
          </EGDSSpacing>
        )}
        {showNeighborhood && neighborhoodName && (
          <EGDSLayoutFlex justifyContent="start">
            <EGDSLayoutFlexItem>
              <EGDSSpacing padding={{ blockend: "one" }} margin={{ inlineend: "one" }}>
                <EGDSText>{neighborhoodName}</EGDSText>
              </EGDSSpacing>
            </EGDSLayoutFlexItem>
            {showDistance && (
              <>
                <EGDSLayoutFlexItem>
                  <span>&#8208;</span>
                </EGDSLayoutFlexItem>
                <EGDSLayoutFlexItem>
                  <DistanceOrRating model={model} hotel={hotel} context={context} showIcon={false} />
                </EGDSLayoutFlexItem>
              </>
            )}
          </EGDSLayoutFlex>
        )}
      </div>
    </EGDSSpacing>
  );

  return (
    <>
      {isVariant ? (
        <>
          <Viewport>
            <ViewSmall>
              <EGDSLayoutFlex direction="column" justifyContent="space-between" blockSize="full_size">
                <EGDSLayoutFlex justifyContent="space-between" wrap="wrap">
                  <EGDSLayoutFlexItem shrink={1}>{titleAndDescription}</EGDSLayoutFlexItem>
                </EGDSLayoutFlex>
                {reviewCountAndRating}
                {priceSection}
              </EGDSLayoutFlex>
            </ViewSmall>
            <ViewLarge>
              <EGDSLayoutFlex direction="column" justifyContent="space-between">
                <EGDSLayoutFlex justifyContent="space-between">
                  <EGDSLayoutFlexItem shrink={1}>{titleAndDescription}</EGDSLayoutFlexItem>
                  {priceSection}
                </EGDSLayoutFlex>
                {reviewCountAndRating}
              </EGDSLayoutFlex>
            </ViewLarge>
          </Viewport>
        </>
      ) : (
        <>
          <EGDSLayoutFlex direction="column" justifyContent="space-between">
            <EGDSLayoutFlex justifyContent="space-between">
              <EGDSLayoutFlexItem shrink={1}>{titleAndDescription}</EGDSLayoutFlexItem>
              {priceSection}
            </EGDSLayoutFlex>
            {reviewCountAndRating}
          </EGDSLayoutFlex>
        </>
      )}
    </>
  );
};
