import * as React from "react";

import { EGDSLayoutGrid, EGDSLayoutConditionalGridTrack } from "@egds/react-core/layout-grid";
import { EGDSSpacing } from "@egds/react-core/spacing";

import HotelCard from "../../../ImageLeftSmall/Layouts/GridLayout/HotelCard";
import { ItemKeyHelper } from "components/utility/ItemKeyHelper";

import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { HotelsFlexModuleResult } from "typings/microserviceModels/hotels-flex-module";

interface GridLayoutProps {
  context: ExtendedContextStore;
  model: HotelsFlexModuleResult;
  columns: number & EGDSLayoutConditionalGridTrack;
}

const GridLayout = (props: GridLayoutProps) => {
  const { context, model, columns } = props;
  const { hotels } = model;

  const keyHelper = new ItemKeyHelper("hotels");

  return (
    <EGDSSpacing margin={{ blockstart: "three" }}>
      <EGDSLayoutGrid columns={columns} space="three">
        {hotels.map((hotel, index) => (
          <HotelCard key={keyHelper.next()} context={context} model={model} hotel={hotel} index={index} />
        ))}
      </EGDSLayoutGrid>
    </EGDSSpacing>
  );
};

export default GridLayout;
