import * as React from "react";
import { LocalizedComponentClass, withLocalization } from "bernie-l10n";
import { EGDSIcon, EGDSIconSize } from "@egds/react-core/icons";
import { EGDSText, EGDSParagraph } from "@egds/react-core/text";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { TravelerReviewContent } from "typings/microserviceModels/hotels-flex-module";
import { useLocalization } from "@shared-ui/localization-context";

interface TravelerReviewsContentProps {
  hotelId: string;
  travelerReviewContent?: TravelerReviewContent;
}

export const TravelerReviewsComponent = (props: TravelerReviewsContentProps) => {
  if (!props.travelerReviewContent) {
    return null;
  }
  const { formatText } = useLocalization();
  const { titleKey, contentText } = props.travelerReviewContent;
  const title = titleKey && formatText(titleKey);
  const paragraphText = `${title} ${contentText}`;

  return (
    <EGDSParagraph>
      <EGDSSpacing padding={{ inlineend: "one" }}>
        <EGDSIcon name="sentiment_4" size={EGDSIconSize.SMALL} />
      </EGDSSpacing>
      <EGDSText size={200} inline>
        {paragraphText}
      </EGDSText>
    </EGDSParagraph>
  );
};

export const TravelerReviewsContent: LocalizedComponentClass<TravelerReviewsContentProps> = withLocalization(
  TravelerReviewsComponent
);
