import * as React from "react";
import { EGDSIcon, EGDSIconSize } from "@egds/react-core/icons";
import { LocalizedComponentProps } from "bernie-l10n";
import { isVariantEnabled } from "src/components/utility/experiment";
import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";
import { useLocalization } from "@shared-ui/localization-context";
import { ExtendedHotel } from "src/services/adapters/BexApiPropertyAdapter";
import { getFormattedDistance, roundDistance } from "../../utils/getFormattedDistance";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { EGDSText } from "@egds/react-core/text";

interface NearestLocationProps extends LocalizedComponentProps {
  model?: ExtendedHotel["directDistance"];
  shouldUseImperialDistanceUnits?: boolean;
  context?: ExtendedContextStore;
}

export const NearestLocationContent = (props: NearestLocationProps) => {
  const { model, shouldUseImperialDistanceUnits, context } = props;
  const l10n = useLocalization();

  const isExperimentEnabled = context && isVariantEnabled(context, "Add_Distance_to_Nearest_Points_on_Hotel_cards");

  if (!isExperimentEnabled || !model) {
    return null;
  }
  const distanceValue = roundDistance(model.value, 2);
  const formatterdDistanceValue = getFormattedDistance(distanceValue, context?.locale);

  /*Localization in progress*/
  const keyProperty = shouldUseImperialDistanceUnits
    ? "nearestLocationContent.cityCenterDistance.text.mi"
    : "nearestLocationContent.cityCenterDistance.text.km";

  const text = model.message ?? l10n.formatText(keyProperty, distanceValue, formatterdDistanceValue);

  if (!text) return null;

  return (
    <EGDSSpacing padding={{ blockstart: "three" }}>
      <EGDSLayoutFlex space="two">
        <EGDSLayoutFlexItem>
          <EGDSIcon name="place" size={EGDSIconSize.SMALL} />
        </EGDSLayoutFlexItem>
        <EGDSLayoutFlexItem>
          <EGDSText size={300}>{text}</EGDSText>
        </EGDSLayoutFlexItem>
      </EGDSLayoutFlex>
    </EGDSSpacing>
  );
};
