import * as React from "react";

import { EGDSCard, EGDSCardLink } from "@egds/react-core/cards";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { EGDSFigure, EGDSImage } from "@egds/react-core/images";
import { EGDSHeading, EGDSText } from "@egds/react-core/text";
import { EGDSRating } from "@egds/react-core/rating";
import { useLocalization } from "@shared-ui/localization-context";
import { EGDSSpacing } from "@egds/react-core/spacing";

import PriceLockup from "components/flexComponents/Hotels/views/HotelsViews/components/shared/PriceLockup";
import { PriceLockupAlign } from "components/shared/PriceLockup/typings";
import DistanceOrRating from "components/flexComponents/Hotels/views/HotelsViews/components/shared/DistanceOrRating";
import DescriptionOrReview from "components/flexComponents/Hotels/views/HotelsViews/components/shared/DescriptionOrReview";
import AmenitiesOrRecommendation from "components/flexComponents/Hotels/views/HotelsViews/components/shared/AmenitiesOrRecommendation";
import ValueProposition from "components/flexComponents/Hotels/views/HotelsViews/components/shared/ValueProposition";
import { getHotelLink, getHotelLinkRel } from "components/flexComponents/Hotels/components/HotelCard";

import { ratingIcon } from "components/utility/RatingUtil";
import { TrackedLink } from "components/utility/analytics/TrackedLink";

import { HotelPriceLockup } from "src/components/flexComponents/Hotels/utils/A11y/HotelPriceLockup";
import { getOptimizedImageUrl } from "src/components/flexComponents/Hotels/utils/hotelOptimizedImage";
import { fireAffinityProductCardSelectedClickstreamEvent } from "src/components/flexComponents/Hotels/utils/fireAffinityProductCardSelectedClickstreamEvent";
import { HotelImageCardProps } from "src/components/flexComponents/Hotels/typings";

const HotelCard = (props: HotelImageCardProps) => {
  const { context, model, hotel, index } = props;
  const { callToAction, destinationId } = model;
  const { hotelName, hotelStarRating, hotelFormattedFullAddress } = hotel;

  const { formatText } = useLocalization();

  const hotelImageUrl = getOptimizedImageUrl(hotel, context);

  return (
    <EGDSCard onClick={fireAffinityProductCardSelectedClickstreamEvent(hotel, index)}>
      <EGDSLayoutFlex>
        <EGDSLayoutFlexItem minWidth="half_width" shrink={0}>
          <div>
            <EGDSFigure className="all-image-fit-cover">
              <EGDSImage src={hotelImageUrl} alt={hotelName} placeholderImage lazyLoading="lazy" />
            </EGDSFigure>
          </div>
        </EGDSLayoutFlexItem>

        <EGDSLayoutFlexItem grow={1}>
          <div>
            <EGDSSpacing padding="three">
              <EGDSLayoutFlex direction="column">
                <EGDSLayoutFlex justifyContent="space-between">
                  <EGDSLayoutFlex direction="column">
                    <EGDSHeading tag="h3" size={5} overflow="truncate-2-lines">
                      {hotelName}
                    </EGDSHeading>
                    <EGDSRating
                      rating={hotelStarRating}
                      icon={ratingIcon(context.locale, context.site.id)}
                      className={context.site.brand}
                    />
                    <EGDSText size={300}>{hotelFormattedFullAddress}</EGDSText>
                  </EGDSLayoutFlex>
                  <DistanceOrRating model={model} hotel={hotel} alignment="right" context={context} />
                </EGDSLayoutFlex>
                <EGDSSpacing margin={{ blockstart: "three" }}>
                  <PriceLockup
                    hotel={hotel}
                    hotelLink={getHotelLink(hotel, model.destinationId, context)}
                    align={PriceLockupAlign.LEFT}
                    priceA11y={new HotelPriceLockup(hotel, formatText)}
                    hasGetRatesText
                  />
                </EGDSSpacing>
                <EGDSSpacing margin={{ blockstart: "three" }}>
                  <DescriptionOrReview model={model} hotel={hotel} />
                </EGDSSpacing>
                <EGDSSpacing margin={{ blockstart: "three" }}>
                  <AmenitiesOrRecommendation model={model} hotel={hotel} />
                </EGDSSpacing>
                <EGDSSpacing margin={{ blockstart: "three" }}>
                  <ValueProposition hotel={hotel} />
                </EGDSSpacing>
              </EGDSLayoutFlex>
            </EGDSSpacing>
          </div>
        </EGDSLayoutFlexItem>
      </EGDSLayoutFlex>
      <EGDSCardLink>
        <TrackedLink
          data-testid={`hotelcard-link-${hotelName}`}
          aria-label={hotelName}
          moduleName="hotels"
          rfrr="card"
          rel={getHotelLinkRel(callToAction)}
          href={getHotelLink(hotel, destinationId || context.searchContext.location?.id, context, callToAction)}
        />
      </EGDSCardLink>
    </EGDSCard>
  );
};

export default HotelCard;
