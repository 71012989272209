import * as React from "react";
import { ValueProposition } from "components/flexComponents/Hotels/components/ValueProposition";
import { Hotel } from "typings/microserviceModels/hotels-flex-module";
import { EGDSIcon, EGDSIconSize } from "@egds/react-core/icons";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSText } from "@egds/react-core/text";

export const HotelAddressDistanceToPoiAndValuePropositionCOMET = ({
  hotel,
  address,
  distance,
  showAddress,
  showDistance,
  isViewCarousel,
}: {
  hotel: Hotel;
  address?: string;
  distance?: string;
  showAddress?: boolean;
  showDistance?: boolean;
  isViewCarousel?: boolean;
}) => {
  return (
    <>
      {showAddress && <EGDSText size={300}>{address}</EGDSText>}
      <ValueProposition hotel={hotel} />
      {showDistance && (
        <EGDSSpacing padding={isViewCarousel ? { blockstart: "unset" } : { blockstart: "three" }}>
          <div>
            <EGDSIcon name="place" size={EGDSIconSize.MEDIUM} />
            <EGDSSpacing padding={{ inlinestart: "two" }}>
              <EGDSText inline size={300} overflow={isViewCarousel ? "truncate" : undefined}>
                {distance}
              </EGDSText>
            </EGDSSpacing>
          </div>
        </EGDSSpacing>
      )}
    </>
  );
};
