import * as React from "react";

import { EGDSCard, EGDSCardContentSection, EGDSCardLink } from "@egds/react-core/cards";
import { EGDSFigure, EGDSFigureAspectRatioType, EGDSImage } from "@egds/react-core/images";
import { EGDSHeading, EGDSText } from "@egds/react-core/text";
import { EGDSRating } from "@egds/react-core/rating";
import { EGDSLayoutFlex } from "@egds/react-core/layout-flex";
import { EGDSLayoutGrid } from "@egds/react-core/layout-grid";
import { useLocalization } from "@shared-ui/localization-context";
import { EGDSSpacing } from "@egds/react-core/spacing";

import PriceLockup from "components/flexComponents/Hotels/views/HotelsViews/components/shared/PriceLockup";
import { PriceLockupAlign } from "components/shared/PriceLockup/typings";
import ValueProposition from "components/flexComponents/Hotels/views/HotelsViews/components/shared/ValueProposition";
import DistanceOrRating from "components/flexComponents/Hotels/views/HotelsViews/components/shared/DistanceOrRating";
import DescriptionOrReview from "components/flexComponents/Hotels/views/HotelsViews/components/shared/DescriptionOrReview";
import AmenitiesOrRecommendation from "components/flexComponents/Hotels/views/HotelsViews/components/shared/AmenitiesOrRecommendation";
import { getHotelLink, getHotelLinkRel } from "components/flexComponents/Hotels/components/HotelCard";

import { ratingIcon } from "components/utility/RatingUtil";
import { TrackedLink } from "components/utility/analytics/TrackedLink";

import { getOptimizedImageUrl } from "src/components/flexComponents/Hotels/utils/hotelOptimizedImage";
import { HotelPriceLockup } from "src/components/flexComponents/Hotels/utils/A11y/HotelPriceLockup";
import { fireAffinityProductCardSelectedClickstreamEvent } from "src/components/flexComponents/Hotels/utils/fireAffinityProductCardSelectedClickstreamEvent";
import { HotelImageCardProps } from "src/components/flexComponents/Hotels/typings";

const HotelCard = (props: HotelImageCardProps) => {
  const { hotel, model, context, index } = props;
  const { callToAction, destinationId } = model;
  const { hotelName, hotelStarRating, hotelFormattedFullAddress, payLater, freeCancellation } = hotel;

  const hotelImageUrl = getOptimizedImageUrl(hotel, context);

  const showValueProposition = payLater || freeCancellation;
  const { formatText } = useLocalization();

  return (
    <EGDSCard onClick={fireAffinityProductCardSelectedClickstreamEvent(hotel, index)}>
      <EGDSCardContentSection padded={false}>
        <EGDSLayoutGrid columns={2}>
          <EGDSFigure ratio={EGDSFigureAspectRatioType.R1_1}>
            <EGDSImage src={hotelImageUrl} alt={hotel.hotelName} placeholderImage lazyLoading="lazy" />
          </EGDSFigure>
          <EGDSSpacing padding="two">
            <EGDSLayoutFlex direction="column">
              <EGDSHeading tag="h3" size={5} overflow="truncate-2-lines">
                {hotelName}
              </EGDSHeading>
              <EGDSRating
                rating={hotelStarRating}
                icon={ratingIcon(context.locale, context.site.id)}
                className={context.site.brand}
              />
              <EGDSText size={300} overflow="truncate-2-lines">
                {hotelFormattedFullAddress}
              </EGDSText>
              <EGDSSpacing margin={{ blockstart: "three" }}>
                <PriceLockup
                  hotel={hotel}
                  hotelLink={getHotelLink(hotel, model.destinationId, context)}
                  align={PriceLockupAlign.LEFT}
                  priceA11y={new HotelPriceLockup(hotel, formatText)}
                  hasGetRatesText
                />
              </EGDSSpacing>
            </EGDSLayoutFlex>
          </EGDSSpacing>
        </EGDSLayoutGrid>
      </EGDSCardContentSection>
      <EGDSCardContentSection border="top">
        <EGDSLayoutFlex direction="column">
          <EGDSSpacing margin={{ blockend: "three" }}>
            <div>
              <DistanceOrRating model={model} hotel={hotel} context={context} />
            </div>
          </EGDSSpacing>
          <DescriptionOrReview model={model} hotel={hotel} />
          <EGDSSpacing margin={{ blockstart: "three" }}>
            <AmenitiesOrRecommendation model={model} hotel={hotel} />
          </EGDSSpacing>
        </EGDSLayoutFlex>
      </EGDSCardContentSection>
      {showValueProposition && (
        <EGDSCardContentSection border="top">
          <ValueProposition hotel={hotel} />
        </EGDSCardContentSection>
      )}
      <EGDSCardLink>
        <TrackedLink
          data-testid={`hotelcard-link-${hotel.hotelName}`}
          aria-label={hotelName}
          moduleName="hotels"
          rfrr="card"
          rel={getHotelLinkRel(callToAction)}
          href={getHotelLink(hotel, destinationId || context.searchContext.location?.id, context, callToAction)}
        />
      </EGDSCardLink>
    </EGDSCard>
  );
};

export default HotelCard;
