import { Hotel } from "typings/microserviceModels/hotels-flex-module";
import * as React from "react";
import { EGDSText } from "@egds/react-core/text";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSUnformattedHtml, EGDSUnformattedHtmlReplaceDefaults } from "@egds/react-core/unformatted-html";

export const Description = ({ hotel, truncate }: { hotel: Hotel; truncate?: boolean }) => {
  const { shortDescription } = hotel;
  const overflow = truncate !== undefined && !truncate ? "wrap" : "truncate-3-lines";

  if (!shortDescription || shortDescription.length === 0) {
    return null;
  }

  return (
    <EGDSSpacing padding={{ blockstart: "two" }}>
      <EGDSText overflow={overflow} size={300}>
        <EGDSUnformattedHtml
          elementsToReplace={{
            ...EGDSUnformattedHtmlReplaceDefaults,
            strong: (props) => (
              <EGDSText {...props} inline theme="emphasis" weight="bold">
                {props.children}
              </EGDSText>
            ),
          }}
          htmlToParse={shortDescription}
        />
      </EGDSText>
    </EGDSSpacing>
  );
};
