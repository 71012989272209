// External Libraries
import * as React from "react";
import { observer } from "mobx-react";

// Types
import { Hotel } from "typings/microserviceModels/hotels-flex-module";

// UITK
import { EGDSCardLink, EGDSCard, EGDSCardContentSection } from "@egds/react-core/cards";
import { EGDSLayoutGrid, EGDSLayoutGridItem } from "@egds/react-core/layout-grid";
import { EGDSFigure, EGDSFigureAspectRatioType, EGDSImage } from "@egds/react-core/images";
import { classNames } from "@egds/react-core/utils";

// Components
import { TrackedLink } from "components/utility/analytics/TrackedLink";
import { useHotelsContext } from "components/flexComponents/Hotels/components/HotelsContext";
import {
  SummarizedContent,
  SummarizedContentViewProps,
} from "components/flexComponents/Hotels/components/SummarizedHotelCard/SummarizedContent";

// Utils
import { getOptimizedImageUrl } from "src/components/flexComponents/Hotels/utils/hotelOptimizedImage";
import { useSendClickstreamSelectedEvent } from "components/flexComponents/Hotels/hooks/useSendClickstreamSelectedEvent";

//helpers
import imageProperties from "./helpers/image-properties";

interface SummarizedHotelBaseProps {
  hotel: Hotel;
  isMobile: boolean;
}
interface SummarizedHotelCardProps extends SummarizedHotelBaseProps, SummarizedContentViewProps {
  index: number;
  className?: string;
  imageAspectRatio?: EGDSFigureAspectRatioType;
  isTopImage?: boolean;
  callToActionStyle?: string;
}

export const SummarizedHotelCardComponent: React.FC<SummarizedHotelCardProps> = ({
  hotel,
  isMobile,
  index,
  className,
  imageAspectRatio,
  isTopImage,
  callToActionStyle,
  ...contentViewProps
}) => {
  const { templateComponent, context } = useHotelsContext();
  const hotelImageUrl = getOptimizedImageUrl(hotel, context, imageProperties);
  const { hotelName, hotelInfositeUrl, datelessHotelUrl } = hotel;

  const moduleName = templateComponent.type as string;
  const imageColSpan = isTopImage ? 6 : 2;
  const contentColSpan = isTopImage ? 6 : 4;

  const getRatio = () => {
    if (imageAspectRatio) {
      return imageAspectRatio;
    }
    if (isTopImage) {
      return EGDSFigureAspectRatioType.R21_9;
    }
    if (isMobile) {
      return EGDSFigureAspectRatioType.R1_1;
    }
    return EGDSFigureAspectRatioType.R4_3;
  };

  const contentProps = {
    hotel,
    ...contentViewProps,
  };

  const hasFullCardLink = callToActionStyle === "fullCard";
  const linkId = `hotelcard-link-${hasFullCardLink ? "full-card-" : ""}${index}`;

  const onClick = (e: any) => {
    e.preventDefault();
    window.location.href = hotelInfositeUrl;
  };

  const handleClick = (e: any) => {
    e.target.href = hotelInfositeUrl;
  };
  const link = (
    <div onClick={useSendClickstreamSelectedEvent(hotel, index)}>
      <EGDSCardLink>
        {datelessHotelUrl ? (
          <TrackedLink
            moduleName={moduleName}
            rfrr="card"
            data-testid={linkId}
            onClick={onClick}
            onContextMenu={handleClick}
            href={datelessHotelUrl}
          >
            {hotelName}
          </TrackedLink>
        ) : (
          <TrackedLink data-testid={linkId} moduleName={moduleName} rfrr="card" href={hotelInfositeUrl}>
            {hotelName}
          </TrackedLink>
        )}
      </EGDSCardLink>
    </div>
  );

  return (
    <EGDSCard className={classNames("SummarizedHotelCard", className)} border>
      <EGDSLayoutGrid columns={6} data-testid="summarized-hotelcard">
        <EGDSLayoutGridItem colSpan={imageColSpan}>
          <EGDSFigure ratio={getRatio()}>
            <EGDSImage src={hotelImageUrl} alt={hotelName} placeholderImage lazyLoading="lazy" />
          </EGDSFigure>
        </EGDSLayoutGridItem>
        <EGDSLayoutGridItem colSpan={contentColSpan}>
          <EGDSCardContentSection>
            <SummarizedContent {...contentProps} />
            {!hasFullCardLink && link}
          </EGDSCardContentSection>
        </EGDSLayoutGridItem>
      </EGDSLayoutGrid>
      {hasFullCardLink && link}
    </EGDSCard>
  );
};

export const SummarizedHotelCard = observer(SummarizedHotelCardComponent);

SummarizedHotelCard.displayName = "SummarizedHotelCard";
