import * as React from "react";
import { EGDSHeading, EGDSText } from "@egds/react-core/text";
import { EGDSSpacing } from "@egds/react-core/spacing";

interface ModuleTitleProps {
  heading: string;
  subHeading?: string;
}

export const ModuleTitle: React.FC<ModuleTitleProps> = ({ heading, subHeading }) => {
  return (
    <>
      <EGDSSpacing margin={{ blockend: "one" }}>
        <EGDSHeading tag="h2" size={4}>
          {heading}
        </EGDSHeading>
      </EGDSSpacing>
      {subHeading && <EGDSText size={300}>{subHeading}</EGDSText>}
    </>
  );
};

ModuleTitle.displayName = "ModuleTitle";
