import * as React from "react";

import { EGDSCard, EGDSCardContentSection, EGDSCardLink } from "@egds/react-core/cards";
import { EGDSFigure, EGDSFigureAspectRatioType, EGDSImage } from "@egds/react-core/images";
import { EGDSScrim } from "@egds/react-core/scrim";
import { EGDSSubheading, EGDSText } from "@egds/react-core/text";
import { EGDSRating } from "@egds/react-core/rating";
import { EGDSLayoutFlex } from "@egds/react-core/layout-flex";
import { EGDSSpacing } from "@egds/react-core/spacing";

import PriceLockup from "components/flexComponents/Hotels/views/HotelsViews/components/shared/PriceLockup";
import ValueProposition from "components/flexComponents/Hotels/views/HotelsViews/components/shared/ValueProposition";
import DistanceOrRating from "components/flexComponents/Hotels/views/HotelsViews/components/shared/DistanceOrRating";
import DescriptionOrReview from "components/flexComponents/Hotels/views/HotelsViews/components/shared/DescriptionOrReview";
import AmenitiesOrRecommendation from "components/flexComponents/Hotels/views/HotelsViews/components/shared/AmenitiesOrRecommendation";
import { getHotelLink, getHotelLinkRel } from "components/flexComponents/Hotels/components/HotelCard";

import { ratingIcon } from "components/utility/RatingUtil";
import { TrackedLink } from "components/utility/analytics/TrackedLink";
import { getOptimizedImageUrl } from "src/components/flexComponents/Hotels/utils/hotelOptimizedImage";

import { HotelPriceLockup } from "src/components/flexComponents/Hotels/utils/A11y/HotelPriceLockup";
import { useLocalization } from "@shared-ui/localization-context";
import { fireAffinityProductCardSelectedClickstreamEvent } from "src/components/flexComponents/Hotels/utils/fireAffinityProductCardSelectedClickstreamEvent";
import { HotelImageCardProps } from "src/components/flexComponents/Hotels/typings";

const HotelCard = (props: HotelImageCardProps) => {
  const { context, model, hotel, index } = props;
  const { callToAction, destinationId } = model;
  const { hotelName, hotelStarRating, hotelFormattedFullAddress } = hotel;

  const hotelImageUrl = getOptimizedImageUrl(hotel, context);

  const { formatText } = useLocalization();

  return (
    <EGDSCard onClick={fireAffinityProductCardSelectedClickstreamEvent(hotel, index)}>
      <EGDSFigure ratio={EGDSFigureAspectRatioType.R21_9}>
        <EGDSImage src={hotelImageUrl} alt={hotelName} placeholderImage lazyLoading="lazy" />
        <EGDSScrim>
          <EGDSSubheading tag="h3">
            <EGDSText inline size={500}>
              {hotelName}
            </EGDSText>
          </EGDSSubheading>
          <EGDSRating
            rating={hotelStarRating}
            icon={ratingIcon(context.locale, context.site.id)}
            className={context.site.brand}
          />
          <EGDSText size={300}>{hotelFormattedFullAddress}</EGDSText>
        </EGDSScrim>
      </EGDSFigure>
      <EGDSCardContentSection>
        <EGDSLayoutFlex justifyContent="space-between">
          <EGDSLayoutFlex direction="column">
            <EGDSSpacing margin={{ blockend: "four" }}>
              <ValueProposition hotel={hotel} />
            </EGDSSpacing>
            <DistanceOrRating model={model} hotel={hotel} context={context} />
          </EGDSLayoutFlex>
          <EGDSLayoutFlex>
            <PriceLockup
              hotel={hotel}
              hotelLink={getHotelLink(hotel, model.destinationId, context)}
              priceA11y={new HotelPriceLockup(hotel, formatText)}
              hasGetRatesText
              textAlignment="right"
            />
          </EGDSLayoutFlex>
        </EGDSLayoutFlex>
      </EGDSCardContentSection>
      <EGDSCardContentSection border="top">
        <DescriptionOrReview model={model} hotel={hotel} />
        <EGDSSpacing margin={{ blockstart: "three" }}>
          <AmenitiesOrRecommendation model={model} hotel={hotel} />
        </EGDSSpacing>
      </EGDSCardContentSection>
      <EGDSCardLink>
        <TrackedLink
          data-testid={`hotelcard-link-${hotelName}`}
          aria-label={hotelName}
          moduleName="hotels"
          rfrr="card"
          rel={getHotelLinkRel(callToAction)}
          href={getHotelLink(hotel, destinationId || context.searchContext.location?.id, context, callToAction)}
        />
      </EGDSCardLink>
    </EGDSCard>
  );
};

export default HotelCard;
