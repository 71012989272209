import * as React from "react";
import { AnalyticsStore } from "bernie-plugin-mobx";
import { useLocalization } from "@shared-ui/localization-context";

import { EGDSCard, EGDSCardContentSection, EGDSCardLink } from "@egds/react-core/cards";
import { EGDSFigure, EGDSFigureAspectRatioType, EGDSImage } from "@egds/react-core/images";
import { EGDSHeading, EGDSText } from "@egds/react-core/text";
import { EGDSRating } from "@egds/react-core/rating";
import { EGDSLayoutFlex } from "@egds/react-core/layout-flex";
import { EGDSLayoutGrid, EGDSLayoutGridItem } from "@egds/react-core/layout-grid";
import { EGDSExpandoLink } from "@egds/react-core/expando";
import { EGDSPrimaryButton } from "@egds/react-core/button";
import { AnalyticsConfig } from "@egds/react-core/utils";
import { EGDSSpacing } from "@egds/react-core/spacing";

import PriceLockup from "components/flexComponents/Hotels/views/HotelsViews/components/shared/PriceLockup";
import ValueProposition from "components/flexComponents/Hotels/views/HotelsViews/components/shared/ValueProposition";
import DistanceOrRating from "components/flexComponents/Hotels/views/HotelsViews/components/shared/DistanceOrRating";
import DescriptionOrReview from "components/flexComponents/Hotels/views/HotelsViews/components/shared/DescriptionOrReview";
import AmenitiesOrRecommendation from "components/flexComponents/Hotels/views/HotelsViews/components/shared/AmenitiesOrRecommendation";
import { getOptimizedImageUrl } from "src/components/flexComponents/Hotels/utils/hotelOptimizedImage";
import {
  expandoCollapsedTitle,
  expandoExpandedTitle,
} from "components/flexComponents/Hotels/views/HotelsViews/HotelUtils";
import { getHotelLink, getHotelLinkRel } from "components/flexComponents/Hotels/components/HotelCard";

import { ratingIcon } from "components/utility/RatingUtil";
import { TrackedLink } from "components/utility/analytics/TrackedLink";
import { FlexClickTracker } from "components/utility/analytics/FlexClickTracker/FlexClickTracker";
import { Action, FlexTrackingInfo, sendDelayedTrackEvent } from "components/utility/analytics/FlexAnalyticsUtils";

import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { Hotel, HotelsFlexModuleResult } from "typings/microserviceModels/hotels-flex-module";
import { withStores } from "stores";
import { HotelPriceLockup } from "src/components/flexComponents/Hotels/utils/A11y/HotelPriceLockup";
import { fireAffinityProductCardSelectedClickstreamEvent } from "src/components/flexComponents/Hotels/utils/fireAffinityProductCardSelectedClickstreamEvent";

interface HotelImageCardProps {
  context: ExtendedContextStore;
  model: HotelsFlexModuleResult;
  hotel: Hotel;
  analytics: AnalyticsStore;
  index: number;
}

const HotelCard = withStores("analytics")((props: HotelImageCardProps) => {
  const { hotel, model, context, index } = props;
  const { callToAction, contentRecipe, destinationId } = model;
  const { hotelId, hotelName, hotelStarRating, hotelFormattedFullAddress } = hotel;

  const hotelImageUrl = getOptimizedImageUrl(hotel, context);

  const { formatText } = useLocalization();

  const [isExpandoVisible, setIsExpandoVisible] = React.useState(false);

  /* covered in e2e test */
  /* istanbul ignore next */
  const expandoLinkAnalytics: AnalyticsConfig = {
    id: "LP.Hotels.HotelImageLeftSmall.ExpandoLink",
    callback: (theId, description) => {
      const flexTrackingInfo: FlexTrackingInfo = {
        moduleName: theId,
        action: Action.CLICK,
        linkName: description,
      };
      sendDelayedTrackEvent(flexTrackingInfo, props.analytics);
    },
  };

  const ButtonTracked = FlexClickTracker(EGDSPrimaryButton);

  return (
    <EGDSCard>
      <div onClick={fireAffinityProductCardSelectedClickstreamEvent(hotel, index)}>
        <EGDSCardContentSection padded={false}>
          <EGDSCardContentSection>
            <EGDSLayoutGrid columns={3} space="three">
              <EGDSLayoutGridItem colSpan={1}>
                <EGDSFigure ratio={EGDSFigureAspectRatioType.R1_1} className="uitk-card-roundcorner-all">
                  <EGDSImage src={hotelImageUrl} alt={hotel.hotelName} placeholderImage lazyLoading="lazy" />
                </EGDSFigure>
              </EGDSLayoutGridItem>

              <EGDSLayoutGridItem colSpan={2}>
                <EGDSLayoutFlex direction="column">
                  <EGDSHeading tag="h3" size={5} overflow="truncate-2-lines">
                    {hotelName}
                  </EGDSHeading>
                  <EGDSRating
                    rating={hotelStarRating}
                    icon={ratingIcon(context.locale, context.site.id)}
                    className={context.site.brand}
                  />
                  <EGDSText size={300}>{hotelFormattedFullAddress}</EGDSText>
                </EGDSLayoutFlex>
              </EGDSLayoutGridItem>
            </EGDSLayoutGrid>
          </EGDSCardContentSection>

          <EGDSCardContentSection border="top">
            <EGDSLayoutFlex justifyContent="space-between">
              <EGDSLayoutFlex direction="column">
                <EGDSSpacing margin={{ blockend: "four" }}>
                  <ValueProposition hotel={hotel} />
                </EGDSSpacing>
                <DistanceOrRating model={model} hotel={hotel} context={context} />
              </EGDSLayoutFlex>
              <EGDSLayoutFlex>
                <PriceLockup
                  hotel={hotel}
                  hotelLink={getHotelLink(hotel, model.destinationId, context)}
                  priceA11y={new HotelPriceLockup(hotel, formatText)}
                  hasGetRatesText
                  textAlignment="right"
                />
              </EGDSLayoutFlex>
            </EGDSLayoutFlex>
          </EGDSCardContentSection>

          <EGDSCardLink>
            <TrackedLink
              data-testid={`hotelcard-link-${hotel.hotelName}`}
              aria-label={hotel.hotelName}
              moduleName="hotels"
              rfrr="card"
              rel={getHotelLinkRel(callToAction)}
              href={getHotelLink(hotel, destinationId || context.searchContext.location?.id, context, callToAction)}
            />
          </EGDSCardLink>
        </EGDSCardContentSection>
      </div>
      <EGDSCardContentSection border="top">
        <EGDSExpandoLink
          isVisible={isExpandoVisible}
          expandoTitle={expandoCollapsedTitle(contentRecipe)}
          expandedLabel={expandoExpandedTitle(contentRecipe)}
          analytics={expandoLinkAnalytics}
          onToggle={() => setIsExpandoVisible((prevIsVisible) => !prevIsVisible)}
          data-testid={`expando-hotelcard-${hotelId}`}
        >
          <EGDSSpacing margin={{ blockstart: "three" }}>
            <DescriptionOrReview model={model} hotel={hotel} />
          </EGDSSpacing>
          <EGDSSpacing margin={{ blockstart: "three" }}>
            <AmenitiesOrRecommendation model={model} hotel={hotel} />
          </EGDSSpacing>
          <EGDSSpacing margin={{ blockstart: "three" }}>
            <ButtonTracked
              href={getHotelLink(hotel, destinationId || context.searchContext.location?.id, context, callToAction)}
              isFullWidth
              moduleName="LP.hotels.hotelImageLeftSmall"
              rfrr="expandoExpanded.select"
              onClick={fireAffinityProductCardSelectedClickstreamEvent(hotel, index)}
            >
              {formatText("hotelsViews.expando.select")}
            </ButtonTracked>
          </EGDSSpacing>
        </EGDSExpandoLink>
      </EGDSCardContentSection>
    </EGDSCard>
  );
});

export default HotelCard;
